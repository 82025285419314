<!--
 * @Description: 创建任务单-新装
 * @Author: ChenXueLin
 * @Date: 2021-10-11 16:42:17
 * @LastEditTime: 2023-02-20 16:18:12
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view">
    <section class="edit-wrapper">
      <div class="edit-wrapper__body">
        <!-- 基本信息 start -->
        <template>
          <div class="primaryTitle">安装任务</div>
          <div class="edit-title">基本信息</div>
          <div class="edit-content">
            <el-form
              label-width="120px"
              ref="f"
              :inline="true"
              :model="customerForm"
              :rules="customerFormRules"
            >
              <el-form-item label="客户名称" prop="corpName">
                <e6-vr-select
                  v-model="customerForm.corpName"
                  :data="typeList"
                  placeholder="客户名称"
                  title=""
                  clearable
                ></e6-vr-select>
              </el-form-item>
              <el-form-item label="联系人" prop="contactName">
                <div class="select-content">
                  <e6-vr-select
                    v-model="customerForm.contactName"
                    :data="typeList"
                    placeholder="联系人"
                    title=""
                    clearable
                  ></e6-vr-select>
                  <el-button type="text" style="margin-left: 10px">
                    添加联系人
                  </el-button>
                </div>
              </el-form-item>
              <el-form-item label="联系电话" prop="contactPhone">
                <el-input
                  placeholder="联系电话"
                  v-model="customerForm.contactPhone"
                ></el-input>
              </el-form-item>
              <el-form-item label="联系地址" prop="province">
                <div class="address-box">
                  <e6-city
                    :format="['区县']"
                    :clearable="false"
                    :placeholder="'省/市/县'"
                  ></e6-city>
                  <!--  @selected="citySelect" -->
                  <el-input
                    placeholder="详细地址"
                    v-model="customerForm.insAddress"
                  ></el-input>
                </div>
              </el-form-item>
              <el-form-item label="要求完成时间" prop="expecttime">
                <el-date-picker
                  v-model="customerForm.expecttime"
                  type="datetime"
                  placeholder="选择日期"
                  value-format="timestamp"
                  popper-class="special"
                  :picker-options="pickerOptions"
                ></el-date-picker>
              </el-form-item>
              <el-form-item label="备注" prop="remark">
                <el-input
                  placeholder="请输入备注"
                  type="textarea"
                  :rows="3"
                  v-model="customerForm.remark"
                  style="width: 500px"
                  maxlength="100"
                ></el-input>
              </el-form-item>
            </el-form>
            <el-form
              :inline="true"
              :model="customerForm"
              class="line-form"
              :rules="customerFormRules"
            >
              <el-form-item label="计费方式" prop="billingMethod">
                <e6-vr-select
                  v-model="customerForm.billingMethod"
                  :data="typeList"
                  placeholder="计费方式"
                  title=""
                  clearable
                ></e6-vr-select>
              </el-form-item>
              <el-form-item label="业务类型" prop="businessType">
                <e6-vr-select
                  v-model="customerForm.businessType"
                  :data="typeList"
                  placeholder="业务类型"
                  title=""
                  clearable
                ></e6-vr-select>
              </el-form-item>
            </el-form>
          </div>
        </template>
        <!-- 基本信息 end -->
        <!-- 任务项 start -->
        <template>
          <div class="edit-title">任务项</div>
          <div class="edit-content" style="margin-bottom: 20px">
            <div class="associated-info">
              <div class="left-info" v-if="hasBindOrder">
                <i class="el-icon-document"></i>
                <span
                  style="
                        font-weight: 600;
                        margin-left: 5px;
                        margin-right: 5px;
                      "
                >
                  已关联订单:
                </span>
                <span>123456</span>
              </div>
              <div class="right-button">
                <el-button type="primary" @click="handleAddGood"
                  >添加商品</el-button
                >
                <el-button type="primary" @click="bindOrder"
                  >关联订单</el-button
                >
              </div>
            </div>
            <!-- 关联订单table  start -->
            <el-table :data="tableData" highlight-current-row height="100px">
              <el-table-column
                show-overflow-tooltip
                v-for="(column, index) in columnData"
                :key="index"
                :prop="column.fieldName"
                :label="column.fieldLabel"
                :min-width="column.width"
                :fixed="column.fixed"
                :align="column.align"
                header-align="center"
              ></el-table-column>
            </el-table>
            <!-- 关联订单table  end -->
          </div>
        </template>
        <!-- 配置信息 -->
        <install-config
          v-for="(item, index) in list"
          :key="index"
        ></install-config>
        <!-- 任务项 end -->
      </div>
      <div class="edit-wrapper__footer">
        <el-button class="cancel">取消</el-button>
        <el-button type="primary">确定</el-button>
      </div>
    </section>

    <!-- 关联订单弹框 -->
    <bindOrder
      :bindOrderDialog="bindOrderDialog"
      @handleCloseBIndOrder="handleCloseBIndOrder"
    ></bindOrder>
    <!-- 添加商品弹框 -->
    <add-goods
      :addGoodsDialog="addGoodsDialog"
      @handleCloseAddGoods="handleCloseAddGoods"
    >
    </add-goods>
    <!-- 选择场景 -->
    <select-scene
      :selectSceneDialog="selectSceneDialog"
      @handleCloseSelectScene="handleCloseSelectScene"
      @handleClose="handleClose"
    ></select-scene>
    <!-- 选择地址 -->
    <select-address
      :selectAddreeDialog="selectAddreeDialog"
      @handleCloseAddress="handleCloseAddress"
    ></select-address>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import InstallConfig from "@/components/workOrderManage/installConfig.vue";
import bindOrder from "@/components/workOrderManage/bindOrder.vue";
import addGoods from "@/components/workOrderManage/addGoods.vue";
import selectScene from "@/components/workOrderManage/selectScene.vue";
import selectAddress from "@/components/workOrderManage/selectAddress.vue";
import { telAndMobileValid } from "@/utils/validate";
export default {
  name: "",
  components: {
    InstallConfig,
    bindOrder,
    addGoods,
    selectScene,
    selectAddress
  },
  data() {
    return {
      list: [1],
      customerForm: {
        billingMethod: "", //计费方式
        businessType: "", //业务类型
        orderCode: "", //订单编号
        corpId: "", //客户id
        corpName: "", //客户名称
        expecttime: "", //期待解决时间
        linkmanrpid: "", //联系人ID
        contactPhone: "", //联系人电话
        contactName: "", //联系人名称
        province: "", //省份
        city: "", //城市
        insCounty: "", //区
        insAddress: "", //详细地址
        defaultCity: "",
        remark: "" //备注
      }, //客户基本信息
      customerFormRules: {
        corpName: [
          {
            required: true,
            message: "请选择客户",
            trigger: ["blur", "change"]
          }
        ],
        contactName: [
          {
            required: true,
            message: "请选择联系人",
            trigger: ["blur", "change"]
          }
        ],
        contactPhone: telAndMobileValid.required({
          requiredMsg: "请输入手机号码",
          message: "号码格式不正确",
          trigger: ["blur", "change"]
        }),
        defaultCity: [
          {
            required: true,
            message: "请选择地区",
            trigger: ["blur", "change"]
          }
        ],
        expecttime: [
          {
            required: true,
            message: "请选择要求完成时间",
            trigger: ["blur", "change"]
          }
        ],
        billingMethod: [
          {
            required: true,
            message: "请选择计费方式",
            trigger: ["blur", "change"]
          }
        ],
        businessType: [
          {
            required: true,
            message: "请选择业务类型",
            trigger: ["blur", "change"]
          }
        ]
      }, //客户基本信息校验
      typeList: [
        {
          id: 1,
          label: "任务单"
        },
        {
          id: 2,
          label: "服务单"
        },
        {
          id: 3,
          label: "协同单"
        }
      ],
      columnData: [
        {
          fieldName: "id",
          display: true,
          fieldLabel: "商品ID",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "name",
          display: true,
          fieldLabel: "商品名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "type",
          display: true,
          fieldLabel: "商品分类",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "size",
          display: true,
          fieldLabel: "类型",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },

        {
          fieldName: "count",
          display: true,
          fieldLabel: "安装总数/可安装数",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //表头
      tableData: [
        {
          name: "1111",
          type: "台",
          size: "100",
          count: 100
        }
      ],
      /*****关联订单*****/
      hasBindOrder: false, //是否已关联订单
      // 关联订单
      bindOrderDialog: false, //关联订单弹框是否显示
      // 设置安装场景
      installSceneDialog: false,
      //添加商品
      addGoodsDialog: false,
      // 选择场景
      selectSceneDialog: false,
      //选择地址
      selectAddreeDialog: false
    };
  },
  mixins: [base, listPage, listPageReszie],
  methods: {
    //添加商品
    handleAddGood() {
      this.addGoodsDialog = true;
    },
    /** 关联订单*/
    //点击关联订单
    bindOrder() {
      this.bindOrderDialog = true;
    },
    // 关闭关联订单弹框
    handleCloseBIndOrder() {
      this.bindOrderDialog = false;
    },
    //**添加商品 */
    handleCloseAddGoods() {
      this.addGoodsDialog = false;
    },
    // 关闭选择场景
    handleCloseSelectScene() {
      this.setSceneDialog = false;
    },
    //关闭地址弹框
    handleCloseAddress() {
      this.selectAddress = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.edit-content {
  padding: 20px 20px 2px;
  .e6-vr-select {
    width: 100%;
  }
  .el-table {
    & tr th > .cell {
      height: 38px;
      line-height: 38px;
      color: #898c91;
      background-color: #ffffff;
    }
    .el-table__body .el-table__row:nth-child(2n) td {
      background-color: #ffffff;
    }
  }
}

.edit-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 15px;
  .operate-action {
    height: 38px;
  }
}
.address-box {
  display: flex;
  align-items: center;
}
.associated-info {
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  .left-info {
    display: flex;
    align-items: center;
    & [class^="el-icon"] {
      font-size: 30px;
      color: #46bfea;
      cursor: pointer;
    }
  }
}
</style>
