<!--
 * @Description: 安装配置表格
 * @Author: ChenXueLin
 * @Date: 2021-08-17 10:32:32
 * @LastEditTime: 2022-04-01 14:19:20
 * @LastEditors: ChenXueLin
-->
<template>
  <div>
    <div>
      <div class="edit-title">安装配置</div>
      <div class="config-content">
        <el-form :inline="true" label-width="120px">
          <el-form-item label="场景名称">顶顶顶顶顶</el-form-item>
          <el-form-item label="数量">2222222</el-form-item>
        </el-form>
        <el-form :inline="true" label-width="120px" class="bg-form">
          <el-form-item label="冷机">
            <e6-vr-select
              v-model="typeId"
              :data="typeList"
              placeholder="冷机"
              title="冷机"
              clearable
            ></e6-vr-select>
          </el-form-item>

          <el-form-item label="电源">
            <e6-vr-select
              v-model="typeId"
              :data="typeList"
              placeholder="电源"
              title="电源"
              clearable
            ></e6-vr-select>
          </el-form-item>

          <el-form-item label="对接要求">
            <e6-vr-select
              v-model="typeId"
              :data="typeList"
              placeholder="对接要求"
              title="对接要求"
              clearable
            ></e6-vr-select>
          </el-form-item>

          <el-form-item label="空调">
            <e6-vr-select
              v-model="typeId"
              :data="typeList"
              placeholder="空调"
              title="空调"
              clearable
            ></e6-vr-select>
          </el-form-item>

          <el-form-item label="液压">
            <e6-vr-select
              v-model="typeId"
              :data="typeList"
              placeholder="液压"
              title="液压"
              clearable
            ></e6-vr-select>
          </el-form-item>

          <el-form-item label="断油断电">
            <e6-vr-select
              v-model="typeId"
              :data="typeList"
              placeholder="断油断电"
              title="断油断电"
              clearable
            ></e6-vr-select>
          </el-form-item>

          <el-form-item label="中控锁">
            <e6-vr-select
              v-model="typeId"
              :data="typeList"
              placeholder="中控锁"
              title="中控锁"
              clearable
            ></e6-vr-select>
          </el-form-item>

          <el-form-item label="卡要求">
            <e6-vr-select
              v-model="typeId"
              :data="typeList"
              placeholder="卡要求"
              title="卡要求"
              clearable
            ></e6-vr-select>
          </el-form-item>

          <el-form-item label="版本要求" class="formInput">
            <el-input style="width:178px"></el-input>
          </el-form-item>

          <el-form-item label="GPS天线位置" class="formInput">
            <el-input style="width:178px"></el-input>
          </el-form-item>
          <el-form-item label="板卡控制">
            <e6-vr-select
              v-model="typeId"
              :data="typeList"
              placeholder="板卡控制"
              title="板卡控制"
              clearable
            ></e6-vr-select>
          </el-form-item>
          <el-form-item label="安装要求" class="bigInput">
            <el-input style="width:400px"></el-input>
          </el-form-item>
        </el-form>
      </div>

      <!-- <el-table
        :data="tableData"
        border
        class="tableClass"
        style="width: 100%;overflow-y:auto;"
        header-cell-class-name="headerCell"
        cell-class-name="cellClass"
      >
        <el-table-column
          fixed="left"
          header-align="center"
          align="center"
          label="*场景名称"
          width="300"
        >
          <el-button type="text" @click="setScene">
            场景名称
          </el-button>
        </el-table-column>
        <el-table-column
          fixed="left"
          header-align="center"
          align="center"
          label="数量"
          width="230"
        >
          2
        </el-table-column>
        <el-table-column
          fixed="left"
          header-align="center"
          align="center"
          label="安装要求"
        >
          <el-form
            class="demo-form-inline"
            :model="formInline"
            :inline="true"
            label-position="right"
          >
            <el-form-item label="冷机">
              <e6-vr-select
                v-model="typeId"
                :data="typeList"
                placeholder="冷机"
                title="冷机"
                clearable
              ></e6-vr-select>
            </el-form-item>

            <el-form-item label="电源">
              <e6-vr-select
                v-model="typeId"
                :data="typeList"
                placeholder="电源"
                title="电源"
                clearable
              ></e6-vr-select>
            </el-form-item>

            <el-form-item label="对接要求">
              <e6-vr-select
                v-model="typeId"
                :data="typeList"
                placeholder="对接要求"
                title="对接要求"
                clearable
              ></e6-vr-select>
            </el-form-item>

            <el-form-item label="空调">
              <e6-vr-select
                v-model="typeId"
                :data="typeList"
                placeholder="空调"
                title="空调"
                clearable
              ></e6-vr-select>
            </el-form-item>

            <el-form-item label="液压">
              <e6-vr-select
                v-model="typeId"
                :data="typeList"
                placeholder="液压"
                title="液压"
                clearable
              ></e6-vr-select>
            </el-form-item>

            <el-form-item label="断油断电">
              <e6-vr-select
                v-model="typeId"
                :data="typeList"
                placeholder="断油断电"
                title="断油断电"
                clearable
              ></e6-vr-select>
            </el-form-item>

            <el-form-item label="中控锁">
              <e6-vr-select
                v-model="typeId"
                :data="typeList"
                placeholder="中控锁"
                title="中控锁"
                clearable
              ></e6-vr-select>
            </el-form-item>

            <el-form-item label="卡要求">
              <e6-vr-select
                v-model="typeId"
                :data="typeList"
                placeholder="卡要求"
                title="卡要求"
                clearable
              ></e6-vr-select>
            </el-form-item>

            <el-form-item label="版本要求" class="formInput">
              <el-input style="width:178px"></el-input>
            </el-form-item>

            <el-form-item label="GPS天线位置" class="formInput">
              <el-input style="width:178px"></el-input>
            </el-form-item>

            <el-form-item label="板卡控制">
              <e6-vr-select
                v-model="typeId"
                :data="typeList"
                placeholder="板卡控制"
                title="板卡控制"
                clearable
              ></e6-vr-select>
            </el-form-item>

            <el-form-item label="安装要求" class="bigInput">
              <el-input style="width:350px"></el-input>
            </el-form-item>
          </el-form>
        </el-table-column>
      </el-table> -->
    </div>
    <div class="config-table">
      <div class="switch-page-button">
        <el-button
          round
          :class="[clickIndex == index ? 'activeButton' : '']"
          v-for="(btn, index) in btnList"
          :key="index"
          @click="switchPage(index)"
          >{{ btn.name }}</el-button
        >
      </div>
      <el-table
        border
        height="300px"
        :data="tableData"
        highlight-current-row
        @selection-change="handleSelectChange"
        :span-method="objectSpanMethod"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column width="160" type="expand">
          <template slot-scope="scope">
            <el-table
              :data="scope.row.children"
              highlight-current-row
              :show-header="false"
            >
              <el-table-column width="55"> </el-table-column>
              <el-table-column width="160"></el-table-column>
              <el-table-column
                show-overflow-tooltip
                v-for="(column, index) in columnData"
                :key="index"
                :prop="column.fieldName"
                :label="column.fieldLabel"
                :min-width="column.width"
                :fixed="column.fixed"
                :align="column.align"
                header-align="center"
              >
              </el-table-column>
            </el-table>
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          v-for="(column, index) in columnData"
          :key="index"
          :prop="column.fieldName"
          :label="column.fieldLabel"
          :min-width="column.width"
          :fixed="column.fixed"
          :align="column.align"
          header-align="center"
        >
          <template slot-scope="{ row }">
            <span v-if="column.fieldName === 'chargeCode'">
              <el-button type="text" @click="handleGoToBillDetail(row)">
                {{ row[column.fieldName] }}
              </el-button>
            </span>
            <span v-else>{{ row[column.fieldName] }}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 安装场景配置弹框 -->
    <set-scene-dialog
      :installSceneDialog="installSceneDialog"
      @handleCloseScene="handleCloseScene"
    ></set-scene-dialog>
  </div>
</template>

<script>
import base from "@/mixins/base";
import setSceneDialog from "./setSceneDialog.vue";
export default {
  name: "taskListManage",
  data() {
    return {
      installSceneDialog: false,
      formInline: {},
      typeId: "",
      clickIndex: 0,
      btnList: [{ name: "总任务项" }, { name: "未分配" }, { name: "待指派" }],
      typeList: [
        {
          id: 1,
          label: "任务单"
        },
        {
          id: 2,
          label: "服务单"
        },
        {
          id: 3,
          label: "协同单"
        }
      ],
      // tableData: [
      //   {
      //     name: "1111",
      //     number: "1234",
      //     config: ""
      //   }
      // ], // 表格数据
      height: 300,
      columnData: [
        {
          fieldName: "id",
          display: true,
          fieldLabel: "商品名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },

        {
          fieldName: "type",
          display: true,
          fieldLabel: "商品分类",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "type",
          display: true,
          fieldLabel: "商品开票名称",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "type",
          display: true,
          fieldLabel: "商品类型",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "installNum",
          display: true,
          fieldLabel: "安装数量",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //配件表头
      tableData: [
        {
          id: 1111,
          pid: 0,
          children: [
            {
              type: "aaaa"
            }
          ]
        },
        {
          pid: 0,
          id: 1111,
          children: [
            {
              type: "bbbb"
            }
          ]
        }
      ] // 配件表格数据
    };
  },
  mixins: [base],
  components: {
    setSceneDialog
  },
  computed: {},
  watch: {},

  methods: {
    objectSpanMethod({ rowIndex, columnIndex }) {
      // row, rowIndex,
      if (columnIndex === 2) {
        // 判断哪一列
        // const _row = this.flitterData(this.tableData).one[rowIndex];
        const _row = [2, 0][rowIndex];
        const _col = _row > 0 ? 1 : 0;
        return {
          rowspan: _row,
          colspan: _col
        };
      }
    },
    //点击筛选框
    switchPage(index) {
      this.clickIndex = index;
    },
    //点击设置场景
    setScene() {
      this.installSceneDialog = true;
    },
    //关闭设置场景弹框
    handleCloseScene() {
      this.installSceneDialog = false;
    },
    //选择配件
    handleSelectChange(val) {
      console.log(val);
    },
    flitterData(arr) {
      let spanOneArr = [];
      let concatOne = 0;
      arr.forEach((item, index) => {
        if (index === 0) {
          spanOneArr.push(1);
        } else {
          if (item.id === arr[index - 1].id) {
            //第一列需合并相同内容的判断条件
            spanOneArr[concatOne] += 1;
            spanOneArr.push(0);
          } else {
            spanOneArr.push(1);
            concatOne = index;
          }
        }
      });
      return {
        one: spanOneArr
      };
    }
  }
};
</script>
<style lang="scss" scoped>
// @import "@/assets/styles/variables.scss";
/deep/.bg-form {
  background: #e8f3ff;
  padding-top: 15px;
}
/deep/.el-table tr th {
  border-color: #fff;
}
/deep/.el-table tr td {
  border-color: #fff;
}
/deep/ .el-table .headerCell {
  border-bottom: 1px solid #fff;
  height: 40px;
  background: #8dc3ff;
  .cell {
    color: #fff;
  }
}
/deep/.cellClass {
  background: #e9f3ff;
}
/deep/.tableClass .el-table__body .el-table__row.hover-row td {
  background: #e9f3ff !important;
}
/deep/.tableClass .el-table .el-table__body .el-table__row.hover-row td {
  background: #e9f3ff !important;
}

/deep/.config-table {
  background: #f9fafc;
  padding: 10px;
  box-sizing: border-box;
  .el-table__body .el-table__row td {
    border-bottom: 1px solid #ebeef5;
    border-left: 1px solid #ebeef5;
    border-right: 1px solid #ebeef5;
  }
  .el-table__body .el-table__row:nth-child(2n) td {
    background-color: #ffffff;
  }
}
.config-content {
  padding: 20px;
  box-sizing: border-box;
  /deep/.el-form {
    .el-form-item {
      margin-bottom: 10px;
    }
  }
  .config-base {
    display: flex;
    & > span {
      width: 50%;
    }
  }
  .el-select {
    width: 150px !important;
  }
}
// .config-warper {
//   width: 100%;
//   .name-row {
//     width: 100%;
//     height: 40px;
//     background: #8dc3ff;
//   }
//   .configName {
//     font-size: 14px;
//     height: 100%;
//     line-height: 40px;
//     text-align: center;
//     color: #fff;
//   }
//   .border {
//     border-right: 1px solid #fff;
//   }
//   .config-row {
//     background: #e9f3ff;
//     .configDiv {
//       display: flex;
//       align-items: center;
//       justify-content: center;
//       color: #45bfe8;
//       font-size: 14px;
//       // height: 175px;
//     }
//     .config-select-wrap {
//       padding: 10px;
//       box-sizing: border-box;
//       /deep/.el-col {
//         min-width: 300px;
//       }
//     }
//     .number {
//       color: #303133;
//     }
//   }
// }

/deep/.el-table__expanded-cell {
  padding: 0px;
}
/deep/.el-table__expanded-cell {
  .el-table__body .el-table__row td {
    background-color: #f9fafc;
    border: none;
  }
}
/deep/.el-table--scrollable-y .el-table__body-wrapper {
  overflow-y: auto !important;
}
</style>
