<!--
 * @Description: 设置安装场景弹框
 * @Author: ChenXueLin
 * @Date: 2021-08-24 13:52:09
 * @LastEditTime: 2022-07-01 09:25:08
 * @LastEditors: ChenXueLin
-->
<template>
  <!-- 设置安装场景 start-->
  <el-dialog
    v-dialogDrag
    title="设置安装场景"
    :visible="installSceneDialog"
    width="900px"
    :close-on-click-modal="false"
    :element-loading-background="loadingBackground"
    :before-close="handleCloseScene"
    custom-class="install-scene-dialog"
  >
    <div class="searchForm">
      <div class="setNum">
        <span>安装场景数量</span>
        <el-input placeholder="安装场景数量"></el-input>
      </div>

      <el-button type="primary">导入场景</el-button>
    </div>
    <el-table border height="300px" :data="installData" highlight-current-row>
      <el-table-column
        show-overflow-tooltip
        v-for="(column, index) in installDataColumn"
        :key="index"
        :prop="column.fieldName"
        :label="column.fieldLabel"
        :min-width="column.width"
        :fixed="column.fixed"
        :align="column.align"
        header-align="center"
      >
        <template slot-scope="{ row }">
          <div v-if="column.fieldName === 'id'">
            <e6-vr-select
              :data="typeList"
              placeholder="任务单二级分类"
              title="任务单二级分类"
              clearable
            ></e6-vr-select>
          </div>
          <span v-else>{{ row[column.fieldName] }}</span>
        </template>
      </el-table-column>
    </el-table>
    <div class="dialog-footer" slot="footer">
      <el-button class="cancel" @click="handleCloseScene">取消</el-button>
      <el-button type="primary">确定</el-button>
    </div>
  </el-dialog>
  <!-- 设置安装场景 end -->
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
export default {
  name: "",
  components: {},
  data() {
    return {
      installDataColumn: [
        {
          fieldName: "id",
          display: true,
          fieldLabel: "场景名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "id",
          display: true,
          fieldLabel: "场景类型",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "id",
          display: true,
          fieldLabel: "车牌颜色/地址（非必填）",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ],
      installData: []
    };
  },
  props: {
    installSceneDialog: {
      type: Boolean,
      default: false
    }
  },
  mixins: [base, listPage, listPageReszie],
  computed: {},
  watch: {},
  methods: {
    //关闭弹框
    handleCloseScene() {
      this.$emit("handleCloseScene");
    }
  },
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {} //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang="scss" scoped>
/deep/.install-scene-dialog .el-dialog__body {
  padding: 20px;
  box-sizing: border-box;
}
.searchForm {
  //   padding: 10px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  .setNum {
    display: flex;
    align-items: center;
    span {
      flex-shrink: 0;
      margin-right: 10px;
    }
  }
}
</style>
