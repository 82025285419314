<!--
 * @Description: 选择地址
 * @Author: ChenXueLin
 * @Date: 2021-08-25 09:26:44
 * @LastEditTime: 2022-07-01 09:24:53
 * @LastEditors: ChenXueLin
-->
<template>
  <el-dialog
    v-dialogDrag
    title="选择地址"
    :visible="selectAddreeDialog"
    width="350px"
    :close-on-click-modal="false"
    :element-loading-background="loadingBackground"
    custom-class="select-address"
    :before-close="handleCloseAddress"
  >
    <div v-if="selectType == 2">
      <el-form ref="addressForm" label-width="88px" :model="addressForm">
        <!--  :rules="rules" -->
        <el-form-item label="省市区" prop="baseTemplateType">
          <div class="address-box">
            <e6-city
              :format="['区县']"
              :clearable="false"
              :placeholder="'省/市/县'"
            ></e6-city>
            <!--  @selected="citySelect" -->
            <el-input placeholder="详细地址"></el-input>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <div v-if="selectType == 1">
      <el-input placeholder="搜索" suffix-icon="el-icon-search"> </el-input>
      <div class="address-list">
        <el-radio-group v-model="address">
          <el-radio
            v-for="item in addressList"
            :label="item.id"
            :key="`address-${item.id}`"
          >
            {{ item.provinceName }}
            {{ item.cityName }}
            {{ item.districtName }}
            {{ item.address }}
          </el-radio>
        </el-radio-group>
      </div>
    </div>
    <div class="dialog-footer" slot="footer">
      <el-button class="cancel" @click="handleCloseAddress">取消</el-button>
      <el-button type="primary">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
export default {
  name: "",
  components: {},
  data() {
    return {
      selectType: 1,
      addressForm: {},
      address: "",
      addressList: [
        {
          address: "国家数字出版基地",
          cityName: "西安市",
          districtName: "雁塔区",
          id: 1,
          location: "陕西省西安市雁塔区",
          provinceName: "陕西省"
        },
        {
          address: "国家数字出版基地",
          cityName: "西安市",
          districtName: "雁塔区",
          id: 2,
          location: "陕西省西安市雁塔区",
          provinceName: "陕西省"
        },
        {
          address: "国家数字出版基地",
          cityName: "西安市",
          districtName: "雁塔区",
          id: 3,
          location: "陕西省西安市雁塔区",
          provinceName: "陕西省"
        },
        {
          address: "国家数字出版基地",
          cityName: "西安市",
          districtName: "雁塔区",
          id: 5,
          location: "陕西省西安市雁塔区",
          provinceName: "陕西省"
        },
        {
          address: "国家数字出版基地",
          cityName: "西安市",
          districtName: "雁塔区",
          id: 7,
          location: "陕西省西安市雁塔区",
          provinceName: "陕西省"
        }
      ]
    };
  },
  props: {
    selectAddreeDialog: {
      type: Boolean,
      default: false
    }
  },
  mixins: [base, listPage, listPageReszie],
  computed: {},
  watch: {},
  methods: {
    citySelect(val) {
      console.log(val);
    },
    handleCloseAddress() {
      this.$emit("handleCloseAddress");
    }
  },
  created() {}
};
</script>
<style lang="scss" scoped>
/deep/ .select-address {
  .el-dialog__body {
    padding: 20px 18px;
  }
  .el-form-item__content {
    & > .el-input {
      width: 220px;
    }
  }
  .address-list {
    width: 100%;
    height: 200px;
    margin-top: 15px;
    overflow-y: auto;
    .el-radio__input.is-checked + .el-radio__label {
      color: #606266;
    }
    .el-radio-group {
      display: flex;
      flex-direction: column;
      .el-radio {
        height: 30px;
        line-height: 30px;
        .el-radio__label {
          font-size: 12px;
          color: #606266;
          & > span {
            margin-left: 30px;
            font-weight: bold;
          }
        }
        &:hover {
          .el-radio__label {
            color: #46bfea;
          }
        }
      }
    }
  }
}
</style>
